import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29')
];

export const server_loads = [0,2];

export const dictionary = {
		"/": [5],
		"/(app)/account/settings": [~6,[2,3]],
		"/(app)/account/settings/customer-portal": [~7,[2,3]],
		"/(app)/account/settings/licensing": [~8,[2,3]],
		"/(app)/account/settings/profile": [~9,[2,3]],
		"/(app)/admin": [~10,[2]],
		"/(app)/admin/[adminId=uuid]/catalog": [~11,[2]],
		"/(app)/admin/[adminId=uuid]/catalog/[trackId=uuid]": [~12,[2]],
		"/(app)/admin/[adminId=uuid]/user": [~13,[2]],
		"/(app)/artist": [~14,[2]],
		"/(app)/artist/[artistId=uuid]/library": [~15,[2]],
		"/(app)/artist/[artistId=uuid]/library/new": [~17,[2]],
		"/(app)/artist/[artistId=uuid]/library/[trackId=uuid]": [~16,[2]],
		"/(app)/artist/[artistId=uuid]/profile": [~18,[2]],
		"/auth/auth-code-error": [22,[4]],
		"/auth/confirm": [~23,[4]],
		"/auth/login": [~24,[4]],
		"/auth/logout": [~25,[4]],
		"/auth/register": [~26,[4]],
		"/auth/register/checkout": [~27,[4]],
		"/auth/reset": [~28,[4]],
		"/auth/update-password": [~29,[4]],
		"/(app)/buyer": [~19,[2]],
		"/(app)/buyer/[buyerId=uuid]/catalog": [~20,[2]],
		"/(app)/buyer/[buyerId=uuid]/catalog/[trackId=uuid]": [~21,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';