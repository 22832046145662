import type { HandleClientError } from '@sveltejs/kit'

import { dev } from '$app/environment'
import { config } from '$lib/client/core/config'
import { logger } from '$lib/client/logging'
import { handleErrorWithSentry, setupSentryClient } from '$lib/shared/sentry'

setupSentryClient({
	dsn: config.sentry.dsn,
	environment: config.sentry.environment,
	// SvelteKit's page store is empty at this point
	origin: window.location.origin
})

logger.info('Starting the app client...')

export const handleError = handleErrorWithSentry((({ error }) => {
	const message = 'Internal Client Error'
	if (dev) {
		console.error(message, error)
	}

	return {
		message,
		status: 500
	}
}) satisfies HandleClientError)
